

export const codes = {
	metaInfo: {
		title: 'reappaypg_agency'
		,copyright: 'REAPPAY Payment'
	}
	,upAgencyList: [
		{ key: 'branchList', name: '지사', use: true, code: 'branchIdx', view: ['master']}
		, { key: 'distributorList', name: '총판', use: true, code: 'distributorIdx', view: ['master', 'branch']}
		, { key: 'agencyList', name: '대리점', use: true, code: 'agencyIdx', view: ['master', 'branch', 'distributor']}
		, { key: 'resellerList', name: '리셀러', use: true, code: 'resellerIdx', view: ['master', 'branch', 'distributor', 'agency']}
		, { key: 'subResellerList', name: '서브 리셀러', use: false, code: 'subResellerIdx'}
	]
	,list_cnt: [
		10, 20, 30, 50, 100, 200
	]
	,merchant_files: [
		{ code: 'bossIdImg', name: '대표자 신분증'}
		, { code: 'businessImg', name: '사업자 등록증'}
		, { code: 'copyOfBankbook', name: '통장 사본'}
	]
	// 사업자 구분
	,P02_0: 0
	,P02_1: 1
	,P02_2: 2
	,P02: [
		{ TOTAL_CODE: '0', code_name: '법인 사업자'}
		, { TOTAL_CODE: '1', code_name: '개인 사업자'}
		, { TOTAL_CODE: '2', code_name: '비사업자'}
	]
	,P03: [
		{ TOTAL_CODE: '1', code_name: '선정산 D+1'}
		, { TOTAL_CODE: '5', code_name: '선정산 D+5'}
	]
	,P13: [
		{ TOTAL_CODE: '', CODE_NAME: '은행'}
		, { TOTAL_CODE: 'P13001', CODE_NAME: '기업은행'}
	]
	,status_agency: [
		{ idx: 1, code: true, name: '정상사용', color: 'green'}
		, { idx: 2, code: false, name: '사용정지', color: 'red'}
	]
	,status: [
		{ idx: 1, code: 1, name: '승인대기'}
		, { idx: 1, code: 2, name: '사용'}
		, { idx: 1, code: 3, name: '미사용'}
		, { idx: 1, code: 4, name: '탈퇴'}
	]
	,search_date_type: [
		{ name: '오늘', type: 'today'}
		, { name: '1주일', type: 'weekly'}
		, { name: '한달', type: 'month'}
		, { name: '선택', type: 'free'}
//		, { name: '3개월', type: '3'}
//		, { name: '6개월', type: '6'}
	]
	,agency_type: [
		{ name: '영업점 구분', code: '', value: ''}
		,{ name: '본사', code: '0', value: 0, key: 'branch'}
		,{ name: '총판', code: '1', value: 1, key: 'distributor'}
		,{ name: '대리점', code: '2', value: 2, key: 'agency'}
		,{ name: '리셀러', code: '3', value: 3, key: 'reseller'}
	]
	,posStatus: [
		{ code: '', code_name: '단말기 상태' }
		,{ code: true, code_name: '정상사용', color: 'green' }
		,{ code: false, code_name: '사용중지', color: 'red' }
	]
	,posType: [
		{ code: '', code_name: '단말기 타입' }
		,{ code: 'Fixed', code_name: '유선 단말기' }
		,{ code: 'Wireless', code_name: '무선 단말기' }
	]
	,merchants: {
		status: [
			{ code: '', code_name: '전체'}
			,{ code: 'Normal', code_name: '정상사용'}
			,{ code: 'Pause', code_name: '사용중지'}
			,{ code: 'Termination', code_name: '해지'}
		]
	}
	,serviceType: [
		{ code: '001', code_name: '인증(온라인)'}
		,{ code: '002', code_name: '비인증(수기)'}
		,{ code: '003', code_name: 'OFF PG(단말기)'}
	]
	,companyType: [
		{ code: 'PUBLIC', name: '법인 사업자'}
		,{ code: 'PRIVATE', name: '개인 사업자'}
		,{ code: 'NON', name: '비사업자'}
	]
	,merchantType: [
		{ code: '001', name: '직영'}
		,{ code: 'Resales', name: '리셀러'}
		,{ code: 'Dealer', name: '딜러'}
	]
	,insurancesNon: {
		insuranceCoverageAmount: ''
		,oneTimeLimit: 2000000
		,monthLimit: 4000000
		,yearLimit: 38000000
	}
	,qna_type: [
		{ code: '', name: '문의 구분', value: ''}
		,{ code: '1', name: '계정/보안', value: ''}
		,{ code: '2', name: '배달비', value: ''}
		,{ code: '3', name: '월세', value: ''}
		,{ code: '4', name: '일반결제', value: ''}
	]
	,qna_status: [
		{ code: '', name: '처리 여부', value: ''}
		,{ code: '0', name: '접수', value: ''}
		,{ code: '1', name: '처리중', value: ''}
		,{ code: '2', name: '답변완료', value: ''}
	]
	,terms_category: [
		{ code: '1', name: '이용약관', value: ''}
		, { code: '2', name: '개인정보 취급방침', value: ''}
		, { code: '3', name: '위치기반 서비스 이용약관', value: ''}
		, { code: '4', name: '전자금융거래 이용약관', value: ''}
	]


	,services: [
		{ total_code: 'quickFee', code_name: '배달비결제'}
		, { total_code: 'monthlyFee', code_name: '월세결제'}
		, { total_code: 'reappayQrFee', code_name: '페이허브 QR'}
		, { total_code: 'cardQrFee', code_name: '신용카드 QR'}
		, { total_code: 'kakaoFee', code_name: '카톡결제'}
		, { total_code: 'handFee', code_name: '수기결제'}
		, { total_code: 'terminalFee', code_name: '단말기 결제'}
	]
	,category_type: {

	}

	,settlement_type: [
		{ code: '1', name: '선정산 D+1'}
		, { code: '5', name: '선정산 D+5'}
	]

	// 영업점 정산 - 정산 구분
	,settlement_agency_type: [
		{ code: '', name: '전체', color: 'blue'}
		,{ code: 'quick', name: '배달비', color: 'blue', value: 'quick'}
		,{ code: 'monthly', name: '월세', color: 'green', value: 'monthly'}
		,{ code: 'd1', name: '선정산 D+1', color: 'green', value: 'oneDay'}
		,{ code: 'd5', name: '선정산 D+5', color: 'red', value: 'fiveDay'}
	]
	,settlement_state2: [
		{ code: '', name: '정산 상태', color: ''}
		,{ code: 0, name: '가정산 실행', color: 'blue'}
		,{ code: 1, name: '지급 확정', color: 'green'}
	]
	,settlement_state: [
		{ code: '', name: '정산 상태', color: ''}
		,{ code: 0, name: '가정산 완료', color: 'blue'}
		,{ code: 1, name: '지급 확정', color: 'green'}
		,{ code: 2, name: '지급 완료', color: 'green'}
		,{ code: 3, name: '지급 보류', color: 'red'}
	]
	,nice_confirm: [
		{ code: true, name: '인증', color: 'green'}
		, { code: false, name: '미인증', color: 'red'}
	]
	,shop_state: [
		{ code: true, name: '정상사용', color: 'green'}
		, { code: false, name: '사용중지', color: 'red'}
	]
	,shop_confirm: [
		{ code: 0, name: '심사대기', color: 'red'}
		, { code: 1, name: '심사완료', color: 'green'}
		, { code: 2, name: '심사반려', color: 'orange'}
	]
	,quick_payment_state: [
		{ code: '', name: '결제상태', color: ''}
		, { code: 0, name: '결제완료', color: 'green'}
		, { code: 1, name: '취소요청', color: 'red'}
		, { code: 2, name: '결제취소', color: 'orange'}
		, { code: 3, name: '취소실패', color: 'red'}
		, { code: 4, name: '결제실패', color: 'orange'}
	]
	// 결제 여부
	,payment_state: [
		{ code: 1, name: '결제완료', color: 'green'}
		, { code: 4, name: '결제취소', color: 'red'}
	]
	,payment_type_qr: 3
	,payment_type: [
		{ code: 0, name: '수기결제', color: 'green'}
		, { code: 1, name: '카톡결제', color: 'red'}
		, { code: 2, name: '단말기결제', color: 'red'}
		, { code: 3, name: 'QR결제', color: 'red'}
		, { code: 4, name: '카드 바코드 결제', color: 'red'}
	]

	,settlement_payment_state: [
		{ code: '', name: '정산 지급 여부', color: ''}
		, { code: 0, name: '지급 대기', color: ''}
		, { code: 1, name: '지급 보류', color: 'red'}
		, { code: 2, name: '지급 완료', color: 'green'}
	]
	,settlement_confirm_state: [
		{code: '', name: '정산 승인 여부', color: 'red', value: ''}
		, {code: false, name: '미승인', color: 'red', value: 0}
		, {code: true, name: '승인', color: 'green', value: 1}
	]
	
	// 월세 서비스 구분
	,monthly_service: [
		{code: 0, name: '결제나라', color: 'red'}
		, {code: 1, name: '페이허브', color: 'green'}
	]
	// 월세 구분
	,monthly_type: [

		{ name: '구분', code: '', value: ''}
		,{ name: '월세', code: '0', value: '0'}
		,{ name: '관리비', code: '1', value: '1'}
		,{ name: '보증금', code: '2', value: '2'}
		,{ name: '이사/수리비', code: '3', value: '3'}
		,{ name: '교육비', code: '4', value: '4'}
		,{ name: '기타', code: '5', value: '5'}
	]
	// 월세 납입 구분
	, monthly_payment: [
		{ name: '납입구분', code: '', value: ''}
		,{ name: '일반', code: 'N', value: '1'}
		,{ name: '정기', code: 'R', value: '2'}
	]

	, montyl_payment_n: 'N'
	// 월세 납입 여부
	, monthly_payment_state: [
		{ name: '납입상태', code: '', color: ''}
		,{ name: '납입', code: true, color: 'green'}
		,{ name: '미납', code: false, color: 'red'}
	]
	// 월세 결제 상태
	, monthly_payment_state2: [
		{ name: '결제상태', code: '', color: ''}
		,{ name: '결제완료', code: true, color: 'green'}
		,{ name: '결제실패', code: false, color: 'red'}
	]
	// 월세 심사 상태
	,monthly_state: [
		{ name: '심사상태', code: '', value: ''}
		,{ name: '심사대기', code: '0', color: ''}
		,{ name: '심사완료', code: '1', color: 'green'}
		,{ name: '심사반려', code: '2', color: 'red'}
	]
	// 차감 상태
	, minus_state: [
		{ name: '차감상태', code: '', value: ''}
		,{ name: '진행중', code: '0', color: 'blue'}
		,{ name: '차감완료', code: '1', color: 'green'}
		,{ name: '차감취소', code: '2', color: 'red'}
	]
	// 마이 정산
	, start_year: 2021
}