<template>
	<div
		class="side full-height overflow-y-auto"
	>
		<div
			class="side-container flex-column "
		>
			<ul
				class="side-content  pb-50"
			>
				<template
					v-for="(item, index) in menu"
				>
					<li
						:key="'menu_' + item.path + '_' + index"
						class="li-side-content "
						:class="'li-side-depth-' + item.depth"
					>
						<div
							class="pa-10 justify-space-between"
						>
							<span
								class="font-weight-bold flex-column justify-center ptb-5"
							>{{ item.name }}</span>
						</div>
						<ul>
							<template
								v-for="(sub, sub_index) in item.subMenus"
							>
								<li
									:key="'sub_' + index + '_' + sub_index + '_' + item.path + sub.path"
									class="pa-10 li-side-sub "
									:class="$route.name == sub.front ? 'on' : 'cursor-pointer'"
									@click="toPush(item, sub)"
								>
									<div
										class="justify-space-between"
									>
										<span class="flex-column justify-center ptb-5">{{ sub.name }}</span>
										<v-icon
											v-show="$route.name != sub.front"
											class="color-bbb"
										>mdi mdi-chevron-right</v-icon>
									</div>
								</li>
							</template>
						</ul>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Side'
	,props: ['user', 'menu']
	,data: function(){
		return {
			items: [
				{
					name: '배달비'
					,code: '001'
					,path: 'Quick'
					,type: ['master', 'distributor', 'agency', 'franchise']
					,sub: [
						{
							name: '배달비 결제'
							,code: '001001'
							,path: 'QuickList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '배달비 정산 실행'
							,code: '001002'
							,path: 'QuickSettlement'
							,type: ['master', 'distributor']
						}
						,{
							name: '배달비 정산 내역'
							,code: '001003'
							,path: 'QuickSettlementList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
					]
				}
				,{
					name: '월세'
					,code: '002'
					,path: 'Monthly'
					,type: ['master', 'distributor', 'agency', 'franchise']
					,sub: [
						{
							name: '월세 심사'
							,code: '002001'
							,path: 'MonthlyReviewList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '월세 납입'
							,code: '002002'
							,path: 'MonthlyPaymentList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '월세 내역'
							,code: '002003'
							,path: 'MonthlySchedule'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
					]
				}

				,{
					name: '영업점'
					,code: '003'
					,path: 'Agency'
					,type: ['master', 'distributor', 'agency', 'franchise']
					,sub: [
						{
							name: '영업점 등록'
							,code: '003001'
							,path: 'AgencyItem'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '하위 영업점 관리'
							,code: '003002'
							,path: 'AgencyList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
					]
				}
				,{
					name: '가맹점'
					,code: '004'
					,path: 'Merchant'
					,type: ['master', 'distributor', 'agency', 'franchise']
					,sub: [
						{
							name: '가맹점 등록'
							,code: '004001'
							,path: 'MerchantItem'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '가맹점 관리'
							,code: '004002'
							,path: 'MerchantList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
            ,{
							name: '배대사 가상계좌 관리'
							,code: '004004'
							,path: 'ShopQuickAccountList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '단말기 관리'
							,code: '004003'
							,path: 'TerminalList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
					]
				}
				,{
					name: '결제 및 정산'
					,code: '005'
					,path: 'Payment'
					,type: ['master', 'distributor', 'agency', 'franchise']
					,sub: [

						{
							name: '결제 내역'
							,code: '005001'
							,path: 'PaymentList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '정산 실행'
							,code: '005000'
							,path: 'Settlement'
							,type: ['master', 'distributor']
						}
						,{
							name: '가맹점 정산내역'
							,code: '005003'
							,path: 'SettlementMerchantList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '가맹점 차감내역'
							,code: '005005'
							,path: 'SettlementMerchantMinusList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '영업점 정산내역'
							,code: '005002'
							,path: 'SettlementAgencyList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
					]
				}
				,{
					name: '게시판 관리'
					,code: '006'
					,path: 'Board'
					,type: ['master', 'distributor', 'agency', 'franchise']
					,sub: [
						{
							name: '공지사항'
							,code: '006001'
							,path: 'NoticeList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '1:1 문의'
							,code: '006002'
							,path: 'QnaList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: 'FAQ'
							,code: '006003'
							,path: 'FaqList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
						,{
							name: '이용약관'
							,code: '007001'
							,path: 'TermsList'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
					]
				}
				,{
					name: '관리'
					,code: '007'
					,path: 'Management'
					,type: ['master', 'distributor', 'agency', 'franchise']
					,sub: [
						{
							name: '마이페이지'
							,code: '007002'
							,path: 'Mypage'
							,type: ['master', 'distributor', 'agency', 'franchise']
						}
					]
				}
			]
		}
	}
	,computed: {
		item_list: function(){
			return this.items.filter(function(item){

				return item.sub.filter(function(sub){

					return sub
				})
			})
		}
	}
	,methods: {
		toPush: function(item, sub){
			this.$emit('push', { name: sub.front})
		}
	}

}
</script>


<style>
	.side {
		background-color: #273956;
		flex-basis: 220px;
	}

	.side-container {
		color: #eee;
	}

	.li-side-sub {
		background-color: #22314B;
		color: #bbb;
	}
	.side .on {
		color: #eee;
		background-color: #22314B;
	}
</style>