<template>
	<Layout
		v-if="is_view"
		:Axios="Axios"
		:Notify="Notify"
		:filter="filter"
		:date="date"
		:rules="rules"
		:Base64="Base64"
		:metaInfo="metaInfo"
		:TOKEN="TOKEN"
		:user="user"
		:codes="codes"
		:menu="menu"
	/>
</template>

<script>

import { Axios } from '@/resources/axios/axios'
import { Notify } from '@/components/AlertMsg'
import { filter } from '@/resources/filter/filter'
import { date } from '@/resources/date/date'
import { rules } from '@/resources/rules/rules'
import { metaInfo } from '@/resources/config/metainfo'
import { codes } from '@/resources/config/codes'

import { Base64 } from 'js-base64'

import Layout from '@/view/Layout/Layout.vue'

export default {

	name: 'App'
	,metaInfo: metaInfo
	,components: { Layout }
	,data: () => ({
		Axios: Axios
		,Notify: Notify
		,date: date
		,filter: filter
		,rules: rules
		,Base64: Base64
		,metaInfo: metaInfo
		,codes: codes
		,TOKEN: ''
		,user: {
			name: ''
			,label_name: ''
			,admin_type_code: ''
			,roleCode: ''
			,roleGroup: ''
			,roleId: ''
		}
		,is_view: false
		,menu: {}
	})
	,methods: {
		isAuth: function(){
			console.log('isAuth start !!')
			let TOKEN = sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT')
			let RT = sessionStorage.getItem(process.env.VUE_APP_NAME + 'RT')
			let skip = false
			let except = ['auth', 'login']
			let path = document.location.href
			except.forEach(function (val) {
				if (path.toLowerCase().indexOf(val) > -1) {
					skip = true
					return false
				}
			})
			if(skip){
				this.is_view = true
				console.log('isAuth skip ! do next !!')
			}else {
				if (!TOKEN || TOKEN === 'false') {
					this.is_view = true
					console.log('not auth ! to login !!')
					this.toLogin()
				}else{
					console.log('auth ! refreshToken !!')
					this.TOKEN = encodeURI(TOKEN)
					this.refreshToken(RT)
					this.getAuth()
					this.setCode()
					this.getMenu()
				}
			}

			console.log('isAuth finished !!')
		}
		,toLogin: function(){
			this.$router.push({ name: 'Login' })
		}
		,refreshToken: async function(token){
			try{
				const result = await this.Axios({
					method: 'post'
					,url: 'auth/refreshToken'
					,data: {
						refreshToken: token
					}
				})

				if(result.success){
					// this.is_view = true
				}else{
					this.toLogin()
				}
			}catch (e) {
				console.log(e)
			}
		}
		,getAgencyTypeList: async function(){
			try{
				const result = await this.Axios({
					method: 'get'
					,url: 'branch/type'
				})

				if(result.success){
					this.$set(this.codes, 'agency_type', result.data)
				}else{
					console.log(result.message)
				}
			}catch (e) {
				console.log(e)
			}
		}
		,getBankList: async function(){
			try{
				this.$emit('onLoading')
				let result = await this.Axios({
					method: 'get'
					, url: 'common/bank'
				})
				if(result.success){
					this.$set(this.codes, 'P13', result.data)
					this.$emit('setNotify', { type: 'error', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,setCode: function(){
			this.getAgencyTypeList()
			this.getBankList()
		}
		,getAuth: async function(){
			try{
				this.$emit('onLoading')
				let result = await this.Axios({
					method: 'get'
					, url: 'auth/info'
				})
				if(result.success){
					this.user = result.data
					this.is_view = true
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getMenu: async function(){
			try{
				this.$emit('onLoading')
				let result = await this.Axios({
					method: 'get'
					, url: 'auth/menu'
				})
				if(result.success){
					this.menu = result.data
                    console.log(this.menu, 'menu list')
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
	}
	,created() {
		this.isAuth()
	}
	,watch: {
	}
}
</script>

<style>
</style>