import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueDaumPostcode from "vue-daum-postcode"
import VueGoogleCharts from 'vue-google-charts'
import { language } from '@/resources/lang/kr/common'
import { storage } from "./resources/storage/storage"

Vue.config.productionTip = false

Vue.use(VueDaumPostcode)
Vue.use(VueGoogleCharts)
Vue.prototype.$language = language
Vue.prototype.$storage = storage

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
