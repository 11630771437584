<template>
	<div
		class="full-height flex-column bg-base theme-dark"
	>
		<Loading
			v-if="is_loading"
		></Loading>

		<Top
			v-if="program.top"
			:user="user"
			@push="push"
		></Top>
		<div class="full-height justify-space-between overflow-y-auto">
			<Side
				v-if="is_side && !program.not_side"
				:user="user"
				:menu="menu"

				@push="push"
			></Side>
			<router-view
				:Axios="Axios"
				:Notify="Notify"
				:user="user"
				:date="date"
				:codes="codes"
				:rules="rules"
				:key="$route.fullPath"
				:query="query"

				@setNotify="setNotify"
				@onLoading="onLoading"
				@offLoading="offLoading"
				@push="push"
				@onLoad="onLoad"

				class="flex-1 pa-10 full-height flex-column overflow-y-auto"
			></router-view>
		</div>
		
		<Notify 
			:msg="notifyCondition.message" 
			:type="notifyCondition.type"
			
			@clearMsg="clearMsg"
		/>
	</div>
</template>

<script>

	import Notify from '@/components/AlertMsg'
	import Loading from "@/view/Layout/Loading";
	import Top from "@/view/Layout/Top";
	import Side from "@/view/Layout/Side";
	
	export default{
		name: 'Layout'
		,props: ['Axios', 'user', 'Notify', 'TOKEN', 'date', 'codes', 'rules', 'menu']
		,components: {Side, Top, Loading, Notify}
		,data: function(){
			return {
				program: {
				}
				,notifyCondition: {
					message: '메시지 입니다'
					,type: ''
				}
				,is_loading: false
				,is_side: false
				,query: {}
			}
		}
		,methods: {
			setNotify: function({type, message}){
				this.notifyCondition = {
					message: message
					,type: type
				}
			}
			,onLoading: function(){
				this.is_loading = true
			}
			,offLoading: function(){
				setTimeout(() => {
					this.is_loading = false
				}, 500)
			}
			,clearMsg: function(){
				this.notifyCondition.message = ''
			}
			,push: function({ name, params, query, not_query }){

				this.$router.push({ name: name, params: params, query: query}).catch(function(e){
					console.log(e)
				})
				if(!not_query) {
					localStorage.removeItem('query')
					this.query = {}
				}else{
					this.setQuery()
				}
			}
			,onLoad: function(program){
				this.program = program
			}
			,setQuery: function(){
				let store = localStorage.getItem('query')
				if(store){
					let query = JSON.parse(store)
					this.query = query
				}
			}
		}
		,created() {
			this.setQuery()
		}
		,watch: {
			TOKEN: {
				immediate: true
				,handler: function(call){
					if(call){
						this.is_side = true
					}else{
						this.is_side = false
					}
				}
			}
		}
	}
	
</script>

<style lang="css" src="@/assets/css/reset.css"></style>
<style lang="css" src="@/assets/css/base.css"></style>


<style>
.v-date-picker-table .v-btn.v-btn--active { color: #c74242 !important;}
.v-picker__body { border: 1px solid #bbb}


.toastui-editor-defaultUI-toolbar button {
	margin: 7px 0px;
}
</style>