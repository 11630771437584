import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	
	{
		path: '/intro'
		,name: 'intro'
		,component: () => import('@/view/Main/Intro.vue')
	}
	,{
		path: '/'
		,name: 'Index'
		,component: () => import('@/view/Main/Index.vue')
	}
	,{
		path: '/Login'
		,name: 'Login'
		,component: () => import('@/view/Auth/Login.vue')
	}
	,{
		path: '/Quick/List'
		,name: 'QuickList'
		,component: () => import('@/view/Quick/QuickList.vue')
	}
	,{
		path: '/Quick/Settlement'
		,name: 'QuickSettlement'
		,component: () => import('@/view/Quick/QuickSettlement.vue')
	}
	,{
		path: '/Quick/Settlement/List'
		,name: 'QuickSettlementList'
		,component: () => import('@/view/Quick/QuickSettlementList.vue')
	}
	,{
		path: '/Quick/Transfer/List'
		,name: 'QuickTransferList'
		,component: () => import('@/view/Quick/QuickTransferList.vue')
	}
	,{
		path: '/Quick/scheduled/settlement'
		,name: 'QuickScheduledSettlement'
		,component: () => import('@/view/Quick/QuickScheduledSettlement.vue')
	}
	,{
		path: '/Monthly/Payment/List'
		,name: 'MonthlyPaymentList'
		,component: () => import('@/view/Monthly/MonthlyPaymentList.vue')
	}
	,{
		path: '/Monthly/Review/List'
		,name: 'MonthlyReviewList'
		,component: () => import('@/view/Monthly/MonthlyReviewList.vue')
	}
	,{
		path: '/Monthly/Schedule'
		,name: 'MonthlySchedule'
		,component: () => import('@/view/Monthly/MonthlySchedule.vue')
	}
	,{
		path: '/Agency/List'
		,name: 'AgencyList'
		,component: () => import('@/view/Agency/AgencyList.vue')
	}
	,{
		path: '/Agency/Item'
		,name: 'AgencyItem'
		,component: () => import('@/view/Agency/AgencyItem.vue')
	}
	,{
		path: '/Agency/Detail/:idx'
		,name: 'AgencyDetail'
		,component: () => import('@/view/Agency/AgencyDetail.vue')
	}
	,{
		path: '/Merchant/List'
		,name: 'MerchantList'
		,component: () => import('@/view/Merchant/MerchantList.vue')
	}
	,{
		path: '/Merchant/Item'
		,name: 'MerchantItem'
		,component: () => import('@/view/Merchant/MerchantItem.vue')
	}
	,{
		path: '/Merchant/Detail/:idx'
		,name: 'MerchantDetail'
		,component: () => import('@/view/Merchant/MerchantDetail.vue')
	}
	,{
		path: '/Merchant/Account'
		,name: 'ShopQuickAccountList'
		,component: () => import('@/view/Merchant/AccountList.vue')
	}
	,{
		path: '/Terminal/List'
		,name: 'TerminalList'
		,component: () => import('@/view/Terminal/TerminalList.vue')
	}
	,{
		path: '/Payment/List'
		,name: 'PaymentList'
		,component: () => import('@/view/Payment/PaymentList.vue')
	}
	/*
	,{
		path: '/Board/:type/List'
		,name: 'BoardList'
		,component: () => import('@/view/Board/BoardList.vue')
	}
	,{
		path: '/Board/:type/Item'
		,name: 'BoardItem'
		,component: () => import('@/view/Board/BoardItem.vue')
	}
	,{
		path: '/Board/:type/Detail/:idx'
		,name: 'BoardDetail'
		,component: () => import('@/view/Board/BoardDetail.vue')
	}
	*/
	,{
		path: '/Board/Notice/Branch'
		,name: 'NoticeBranch'
		,component: () => import('@/view/Board/NoticeBranch.vue')
	}
	,{
		path: '/Board/Notice/List'
		,name: 'NoticeList'
		,component: () => import('@/view/Board/NoticeList.vue')
	}
	,{
		path: '/Board/Notice/Item'
		,name: 'NoticeItem'
		,component: () => import('@/view/Board/NoticeItem.vue')
	}
	,{
		path: '/Board/Notice/Detail/:idx'
		,name: 'NoticeDetail'
		,component: () => import('@/view/Board/NoticeDetail.vue')
	}
	,{
		path: '/Board/Notice/View/:idx'
		,name: 'NoticeView'
		,component: () => import('@/view/Board/NoticeView.vue')
	}
	,{
		path: '/Board/Faq/Category'
		,name: 'FaqCategory'
		,component: () => import('@/view/Board/FaqCategory.vue')
	}
	,{
		path: '/Board/Faq/List'
		,name: 'FaqList'
		,component: () => import('@/view/Board/FaqList.vue')
	}
	,{
		path: '/Board/Faq/Item'
		,name: 'FaqItem'
		,component: () => import('@/view/Board/FaqItem.vue')
	}
	,{
		path: '/Board/Faq/Detail/:idx'
		,name: 'FaqDetail'
		,component: () => import('@/view/Board/FaqDetail.vue')
	}
	,{
		path: '/Board/Qna/List'
		,name: 'QnaList'
		,component: () => import('@/view/Board/QnaList.vue')
	}
	,{
		path: '/Board/Qna/Detail/:idx'
		,name: 'QnaDetail'
		,component: () => import('@/view/Board/QnaDetail.vue')
	}
	,{
		path: '/Settlement'
		,name: 'Settlement'
		,component: () => import('@/view/Settlement/Settlement.vue')
	}
	,{
		path: '/Settlement/Merchant/List'
		,name: 'SettlementMerchantList'
		,component: () => import('@/view/Settlement/SettlementMerchantList.vue')
	}
	,{
		path: '/Settlement/Merchant/Minus/List'
		,name: 'SettlementMerchantMinusList'
		,component: () => import('@/view/Minus/MinusMerchantList')
	}
	,{
		path: '/Settlement/Agency/List'
		,name: 'SettlementAgencyList'
		,component: () => import('@/view/Settlement/SettlementAgencyList.vue')
	}
	,{
		path: '/Settlement/Mypage'
		,name: 'SettlementMypage'
		,component: () => import('@/view/Settlement/SettlementMypage.vue')
	}
	,{
		path: '/Settlement/Agency'
		,name: 'SettlementAgency'
		,component: () => import('@/view/Settlement/SettlementAgency.vue')
	}
	,{
		path: '/Terms/List'
		,name: 'TermsList'
		,component: () => import('@/view/Terms/TermsList.vue')
	}
	,{
		path: '/Terms/History'
		,name: 'TermsHistory'
		,component: () => import('@/view/Terms/TermsHistory.vue')
	}
	,{
		path: '/Terms/Item'
		,name: 'TermsItem'
		,component: () => import('@/view/Terms/TermsItem.vue')
	}
	,{
		path: '/Terms/Detail/:idx'
		,name: 'TermsDetail'
		,component: () => import('@/view/Terms/TermsDetail.vue')
	}
	,{
		path: '/Management/Mypage'
		,name: 'Mypage'
		,component: () => import('@/view/Management/Mypage.vue')
	}
	,{
		path: '/Management/Holiday'
		,name: 'Holiday'
		,component: () => import('@/view/Management/Holiday.vue')
	}
	,{
		path: '*',
		name:'404', 
		component: () => import('@/view/Error/NotFound.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	next();
});

export default router
